<script setup lang="ts">
import type * as icons from '@/assets/icons';

const props = withDefaults(
  defineProps<{
    variant?:
      | 'primary'
      | 'gray'
      | 'plain'
      | 'tertiary'
      | 'danger'
      | 'plain-danger'
      | 'tertiary-danger';
    iconLeft?: keyof typeof icons | '';
    iconRight?: keyof typeof icons | '';
    tagLabel?: string;
    isLoading?: boolean;
    loading?: boolean;
    disabled?: boolean;
  }>(),
  {
    variant: 'primary',
    iconLeft: '',
    iconRight: '',
    showTag: false,
    tagLabel: '',
    isLoading: false,
    loading: false,
    disabled: false,
  }
);

const disabledState = computed(() => {
  return props.disabled || props.loading;
});
</script>

<template>
  <USkeleton
    v-if="props.isLoading"
    class="w-[60px] h-[38px]"
    :ui="{
      rounded: 'rounded-lg',
      background: 'bg-hub-l-gray-400/[0.78] dark:bg-hub-d-gray-400',
    }"
  />

  <button
    v-else
    :class="{
      btn: true,
      gray: props.variant === 'gray',
      tertiary: props.variant.startsWith('tertiary'),
      plain: props.variant.startsWith('plain'),
      'text-[#EB5555]': props.variant.endsWith('-danger'),
      'border border-[#EB5555]': variant === 'tertiary-danger',
      'text-white border-none bg-[#EB5555] hover:bg-[#b64141]':
        props.variant === 'danger',
    }"
    :disabled="disabledState"
  >
    <slot name="icon-left">
      <HIcon
        v-if="props.iconLeft"
        :icon="props.iconLeft"
        class="w-[18px] h-[18px]"
      />
    </slot>

    <slot />

    <slot name="icon-right">
      <HIcon
        v-if="props.iconRight"
        :icon="props.iconRight"
        class="w-[18px] h-[18px]"
      />
    </slot>

    <span
      v-if="props.loading"
      :class="{
        loader: true,
        primary: props.variant === 'primary',
      }"
      before
    ></span>

    <HTag
      v-if="props.tagLabel"
      :clickable="!disabled"
      :class="{
        'border-ht-text-gray-1 bg-ht-btn-gray': props.variant === 'primary',
      }"
      >{{ props.tagLabel }}</HTag
    >
  </button>
</template>

<style lang="scss" scoped>
.btn {
  @apply cursor-pointer relative overflow-hidden transition-all duration-300 ease-hub-ease;
  @apply h-9 px-4 py-2 rounded-lg justify-center items-center gap-2.5 inline-flex;
  @apply text-sm font-semibold leading-[1.4] whitespace-nowrap focus-visible:outline-none;
  @apply text-ht-monochrome-wb bg-ht-monochrome-bw border border-ht-hub-monochrome-bw;

  &:hover {
    @apply bg-transparent text-ht-monochrome-bw;
  }

  &:disabled {
    @apply cursor-not-allowed opacity-70 text-ht-monochrome-wb bg-ht-monochrome-bw;
  }

  &.gray {
    @apply text-ht-btn-gray-label bg-ht-btn-gray border-none;

    &:hover {
      @apply bg-ht-btn-gray-hover;
    }

    &:disabled {
      @apply cursor-not-allowed opacity-80 text-ht-btn-gray-label bg-ht-btn-gray;
    }
  }

  &.tertiary {
    @apply bg-transparent border-ht-btn-tertiary-border text-ht-monochrome-bw;

    &:hover {
      @apply bg-ht-btn-tertiary-hover;
    }

    &:disabled {
      @apply cursor-not-allowed opacity-80 text-ht-monochrome-bw bg-transparent;
    }
  }

  &.plain {
    @apply bg-transparent border-none text-ht-monochrome-bw;

    &:hover {
      @apply bg-ht-btn-tertiary-hover;
    }

    &:disabled {
      @apply cursor-not-allowed opacity-80 text-ht-monochrome-bw bg-transparent;
    }
  }
}

.loader {
  width: 30px;
  height: 12px;
  background:
    radial-gradient(circle closest-side, #766df4 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, #766df4 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, #766df4 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 100%;
  background-repeat: no-repeat;
  animation: d7 1s infinite linear;
}
@keyframes d7 {
  33% {
    background-size:
      calc(100% / 3) 0%,
      calc(100% / 3) 100%,
      calc(100% / 3) 100%;
  }
  50% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 0%,
      calc(100% / 3) 100%;
  }
  66% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 100%,
      calc(100% / 3) 0%;
  }
}
</style>
