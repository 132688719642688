<script setup lang="ts">
/* eslint import/namespace: ['error', { allowComputed: true }] */
import * as icons from '~/assets/icons';

const props = defineProps<{
  icon: keyof typeof icons;
}>();

const icon = computed(() => {
  // @ts-ignore
  return icons[props.icon];
});
</script>
<template>
  <component :is="icon" viewBox="0 0 24 24"></component>
</template>
