<script setup lang="ts">
import type * as icons from '@/assets/icons';

const props = withDefaults(
  defineProps<{
    iconLeft?: keyof typeof icons | '';
    iconRight?: keyof typeof icons | '';
    clickable?: boolean;
    fill?: boolean;
    isTag?: boolean;
    isLoading?: boolean;
  }>(),
  {
    iconLeft: '',
    iconRight: '',
    clickable: true,
    fill: false,
    isTag: false,
    isLoading: false,
  }
);
</script>

<template>
  <USkeleton
    v-if="props.isLoading"
    class="h-6 w-8 px-2"
    :ui="{
      background: 'bg-hub-l-gray-400/[0.68] dark:bg-hub-d-gray-300',
    }"
  />

  <div
    v-else
    class="tag"
    :class="{
      clickable: props.clickable,
      fill: !props.clickable && props.fill,
    }"
  >
    <slot name="icon-left">
      <HIcon
        v-if="props.iconLeft"
        :icon="props.iconLeft"
        class="w-[16px] h-[16px]"
      />
      <HIcon
        v-if="props.isTag"
        icon="LabelI"
        class="w-[16px] h-[16px] text-ht-text-gray-1"
      />
    </slot>

    <slot />

    <slot name="icon-right">
      <HIcon
        v-if="props.iconRight"
        :icon="props.iconRight"
        class="w-[16px] h-[16px]"
      ></HIcon>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.tag {
  @apply overflow-hidden transition-all duration-300 ease-hub-ease;
  @apply h-6 px-2 py-1.5 rounded-md justify-start items-center gap-2 inline-flex;
  @apply text-ht-monochrome-bw text-xs font-medium leading-3 whitespace-nowrap;

  &.clickable {
    @apply cursor-pointer;
    @apply border border-ht-btn-tertiary-border bg-transparent;
    @apply hover:bg-ht-btn-tertiary-hover;
  }

  &.fill {
    @apply bg-ht-cc-rc-bg-checked border-none;
  }
}
</style>
